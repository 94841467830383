import React, { useEffect } from 'react';
import { Flex, Input } from '@chakra-ui/react';
import { signInWithCustomToken } from 'firebase/auth';
import { useMutation } from 'react-query';
import { auth } from '../firebase';
import { api } from '../utils/axiosInstance';

const Howdy = () => {
  const [input, setInput] = React.useState<string>('');
  const [error, setError] = React.useState<number>(0);
  const {
    mutate: submit,
    data,
    error: apiError,
    isError,
    isSuccess,
    isLoading,
  } = useMutation(async () => {
    const response = await api({
      method: 'POST',
      url: `auth/login`,
      data: {
        who: input,
      },
    });
    return response.data;
  });

  useEffect(() => {
    if (isSuccess && data) {
      signInWithCustomToken(auth, data);
    }
  }, [data, isSuccess]);
  useEffect(() => {
    if (isError) {
      setError(1);
    }
  }, [isError, apiError]);

  const borderColor = error === 1 ? 'red.300' : 'inherit';

  return (
    <Flex justifyContent="center" align="center">
      <Flex p={4} justify="center" align="flex-end" h="100vh" pb="40px">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!isLoading) {
              submit();
            }
          }}
        >
          <Input
            type="password"
            focusBorderColor={borderColor}
            disabled={isLoading}
            autoFocus
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              setError(0);
            }}
          />
        </form>
      </Flex>
    </Flex>
  );
};

export default Howdy;
