import React from 'react';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Msg } from '../types';
import { Flex, Text } from '@chakra-ui/react';
import MessageTime from './MessageTime';
import ImageViewer from '../components/ImageViewer';

const Message = ({ message }: { message: Msg }) => {
  const [user] = useAuthState(auth);
  const me = message.uid === user?.uid;

  return (
    <Flex w="100%" justify={`${me ? 'flex-end' : 'flex-start'}`}>
      <Flex
        bg={`${me ? 'black' : 'gray.100'}`}
        color={`${me ? 'white' : 'black'}`}
        minW="100px"
        maxW="80%"
        my="1"
        px="2"
        borderRadius="5px"
        direction="column"
      >
        {message.imgFiles &&
          message.imgFiles.map((fileName) => (
            <ImageViewer key={fileName} fileName={fileName} />
          ))}
        <Text>{message.text}</Text>
        <br />
        <MessageTime
          seconds={message.createdAt?.seconds ?? new Date().getTime()}
        />
      </Flex>
    </Flex>
  );
};

export default Message;
