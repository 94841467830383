import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';

import { auth, database, db } from '../firebase';
import {
  ref,
  onValue,
  onDisconnect,
  set,
  serverTimestamp,
} from 'firebase/database';
import { remoteUser } from '../utils/userUtil';

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(false);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const uid = user?.uid;
    if (uid) {
      const uidRemote = remoteUser(uid);
      // console.log('user: ', user);
      const unsubscribe = onSnapshot(doc(db, `/status/${uidRemote}`), (doc) => {
        const status = doc.data();
        // console.log(`remote:${uidRemote} `);
        // console.table(status);
        const isRemoteOnline = status && status.online;
        // console.log('isRemoteOnline: ', isRemoteOnline);
        setIsOnline(isRemoteOnline);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  useEffect(() => {
    const uid = user?.uid;
    if (uid) {
      const userStatusFirestoreRef = doc(db, `/status/${uid}`);
      const userStatusDatabaseRef = ref(database, '/status/' + uid);

      const connectedRef = ref(database, '.info/connected');

      const unsubscribe = onValue(connectedRef, (snap) => {
        const isOfflineForDatabase = {
          online: false,
          lastChanged: serverTimestamp(),
        };

        const isOnlineForDatabase = {
          online: true,
          lastChanged: serverTimestamp(),
        };

        if (snap.val() === false) {
          setDoc(userStatusFirestoreRef, isOfflineForDatabase);

          return;
        }
        onDisconnect(userStatusDatabaseRef)
          .set(isOfflineForDatabase)
          .then(function () {
            set(userStatusDatabaseRef, isOnlineForDatabase);
            setDoc(userStatusFirestoreRef, isOnlineForDatabase);
          });
      });

      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  // console.log('useIsOnline: ', isOnline);

  return { isOnline };
};

export default useIsOnline;
