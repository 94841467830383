import React, { useEffect, useRef } from 'react';
import { Msg } from '../types';
import Message from './Message';
import MessageInput from './MessageInput';
import Call from './Call';

type Props = {
  messages: Msg[];
  isOnline: boolean;
};

const Messages = ({ messages, isOnline }: Props) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current === null) return;

      // @ts-ignore
      messagesEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 500);
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <>
      <Call />

      <div className="content">
        {messages.map((item) => (
          <Message key={item.id} message={item} />
        ))}
        <div className="scroll-btm" ref={messagesEndRef} />
      </div>

      <div className="footer">
        <MessageInput isOnline={isOnline} />
      </div>
    </>
  );
};

export default Messages;
