import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import Howdy from './Howdy';
import Talks from '../Talks/Talks';
import useIsOnline from '../hooks/useIsOnline';

function Hej() {
  const { isOnline } = useIsOnline();
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null;
  }

  return !user ? <Howdy /> : <Talks isOnline={isOnline} />;
}

export default Hej;
