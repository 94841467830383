import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

export const Die = () => {
  const [user] = useAuthState(auth);
  useEffect(() => {
    if (user) {
      console.log("signOut");
      signOut(auth);
    }
  }, [user]);
  return null;
};
