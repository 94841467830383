import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface UploadContextType {
  uploading: boolean;
  setUploading: Dispatch<SetStateAction<boolean>>;
}

export const UploadContext = React.createContext<UploadContextType>(
  {} as UploadContextType,
);

const UploadProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [uploading, setUploading] = useState(false);

  return (
    <UploadContext.Provider value={{ uploading, setUploading }}>
      {children}
    </UploadContext.Provider>
  );
};

const useUpload = (): UploadContextType => {
  const context = useContext(UploadContext);

  if (context === undefined) {
    throw new Error('useUpload must be used within an UploadProvider');
  }
  return context;
};

export { UploadProvider, useUpload };
