import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Textarea,
  Box,
  Icon,
  CloseButton,
  IconButton,
} from '@chakra-ui/react';
import { auth, db } from '../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import FileUpload from '../components/FileUpload';
import { FiFile } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { MESSAGE_COLLECTION } from '../App.constant';
import { api } from '../utils/axiosInstance';
import { useCall } from '../contexts/CallContext';
import { PhoneIcon } from '@chakra-ui/icons';
import ImageViewer from '../components/ImageViewer';

type FormValues = {
  file_: FileList;
};

type Props = {
  isOnline: boolean;
};

const MessageInput = ({ isOnline }: Props) => {
  const { canCall, canEnd, setCalling, setEnding } = useCall();
  const [message, setMessage] = useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imgFiles, setImgFiles] = useState<string[]>([]);
  const formDisabled = !auth.currentUser || isSending;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormValues>();
  const uploadFile = handleSubmit(async (files) => {
    if (!auth.currentUser) {
      return;
    }
    setIsUploading(true);
    const {
      data: { url, fileName },
    } = await api({
      url: 'auth/upload',
      method: 'GET',
    });

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        setImgFiles([...imgFiles, fileName]);
      } else {
        alert('upload failed!');
      }

      setIsUploading(false);
    };

    xhr.onerror = () => {
      setIsUploading(false);
      alert('upload error!');
    };
    xhr.setRequestHeader('Content-Type', files.file_[0].type);
    xhr.send(files.file_[0]);
  });

  const validateFiles = (value: FileList) => {
    console.log('validating files');
    if (value.length < 1) {
      return 'Files is required';
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 20;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 20mb';
      }
    }

    return true;
  };

  useEffect(() => {
    if (errors.file_) {
      alert('upload errors');
    }
  }, [errors]);
  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset]);
  useEffect(() => {
    // TypeScript users
    // const subscription = watch(() => handleSubmit(onSubmit)())
    const subscription = watch(() => uploadFile());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, uploadFile]);

  const sendMessage = async () => {
    if (!auth.currentUser) {
      return;
    }
    if (message.trim() === '' && imgFiles.length === 0) {
      return;
    }
    const { uid } = auth.currentUser;

    setIsSending(true);
    const msgBody = {
      text: message,
      imgFiles,
      createdAt: serverTimestamp(),
      uid,
    };
    await addDoc(collection(db, MESSAGE_COLLECTION), msgBody);
    setMessage('');
    setIsSending(false);
    setImgFiles([]);
  };

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendMessage();
        }}
        className="send-message"
      >
        {imgFiles.length > 0 && (
          <Flex direction="column">
            {imgFiles.map((img, index) => (
              <Flex
                key={img}
                justifyContent="space-between"
                alignItems="center"
              >
                <ImageViewer fileName={img} size={50} />
                <CloseButton
                  onClick={() => {
                    imgFiles.splice(index, 1);
                    setImgFiles([...imgFiles]);
                  }}
                />
              </Flex>
            ))}
          </Flex>
        )}
        <fieldset
          style={{ width: '100%' }}
          disabled={formDisabled || isUploading}
        >
          <Flex border="5px" borderStyle="solid">
            {isOnline && canCall && (
              <IconButton
                onClick={() => setCalling(true)}
                colorScheme="teal"
                aria-label="Call "
                size="sm"
                icon={<PhoneIcon />}
              />
            )}

            {canEnd && (
              <IconButton
                onClick={() => setEnding(true)}
                colorScheme="red"
                aria-label="End"
                size="sm"
                icon={<PhoneIcon />}
              />
            )}
            <Box flex="1">
              <Textarea
                className="send-message-input"
                sx={{
                  bg: 'white',
                  color: '#1c2c4c',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  resize: 'none',
                }}
                placeholder="message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Box>

            <Flex
              width="70px"
              direction="column"
              justifyContent="space-between"
            >
              <FileUpload
                accept={'image/*'}
                // multiple
                register={register('file_', {
                  validate: validateFiles,
                })}
              >
                <Button
                  margin="0"
                  borderRadius="none"
                  leftIcon={<Icon as={FiFile} />}
                >
                  file
                </Button>
              </FileUpload>

              <Button
                margin="0"
                type="submit"
                bg="#7cc5d9"
                color="#242443"
                borderRadius="none"
                // height="100%"
                fontWeight="600"
                size="sm"
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </fieldset>
      </form>
    </div>
  );
};

export default MessageInput;
