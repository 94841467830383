import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
  headers: {
    'Content-Type': 'application/json',
    timeout: 30000,
  },
});

api.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
