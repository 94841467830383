import React, { useEffect, useRef, useState } from 'react';

import { Image } from '@chakra-ui/react';
import './ImageViewer.css'; // Ensure you create and import the CSS file

import { api } from '../utils/axiosInstance';

type Props = {
  fileName: string;
  size?: number;
};
const Picture = (props: Props) => {
  const { fileName, size = 150 } = props;
  const [url, setUrl] = useState('');

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomFactor, setZoomFactor] = useState(1);
  const fullscreenImageRef = useRef<HTMLImageElement>(null);
  const clickTimeout = useRef<number | null>(null);
  const startX = useRef<number | null>(null);
  const startY = useRef<number | null>(null);
  const initialLeft = useRef<number | null>(null);
  const initialTop = useRef<number | null>(null);

  useEffect(() => {
    if (fullscreenImageRef.current) {
      const handleResize = () => {
        const deviceWidth = window.innerWidth;
        const deviceHeight = window.innerHeight;
        if (fullscreenImageRef.current !== null) {
          const imageWidth = fullscreenImageRef.current.naturalWidth;
          const imageHeight = fullscreenImageRef.current.naturalHeight;

          const widthRatio = imageWidth / deviceWidth;
          const heightRatio = imageHeight / deviceHeight;

          setZoomFactor(Math.max(widthRatio, heightRatio));
        }
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isFullscreen]);

  const handleThumbnailClick = () => {
    setIsFullscreen(true);
  };

  const handleOverlayClick = () => {
    if (clickTimeout.current) {
      window.clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      handleOverlayDoubleClick();
    } else {
      clickTimeout.current = window.setTimeout(() => {
        setIsFullscreen(false);
        setIsZoomed(false);
        clickTimeout.current = null;
      }, 200);
    }
  };

  const handleOverlayDoubleClick = () => {
    if (clickTimeout.current) {
      window.clearTimeout(clickTimeout.current);
    }
    setIsZoomed(!isZoomed);
  };

  const getPicture = async (file: string) => {
    const { data } = await api({
      url: 'auth/readfile?fileName=' + file,
      method: 'GET',
    });
    setUrl(data.url);
  };
  useEffect(() => {
    if (fileName.length !== 0) {
      getPicture(fileName);
    }
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isZoomed) {
      startX.current = e.clientX;
      startY.current = e.clientY;
      initialLeft.current = fullscreenImageRef.current?.offsetLeft ?? 0;
      initialTop.current = fullscreenImageRef.current?.offsetTop ?? 0;
      fullscreenImageRef.current!.style.cursor = 'grabbing';
      e.preventDefault();
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isZoomed && startX.current !== null && startY.current !== null) {
      const dx = e.clientX - startX.current;
      const dy = e.clientY - startY.current;
      if (fullscreenImageRef.current) {
        fullscreenImageRef.current.style.left = `${initialLeft.current! + dx}px`;
        fullscreenImageRef.current.style.top = `${initialTop.current! + dy}px`;
      }
    }
  };

  const handleMouseUp = () => {
    if (isZoomed) {
      startX.current = null;
      startY.current = null;
      if (fullscreenImageRef.current) {
        fullscreenImageRef.current.style.cursor = 'grab';
      }
    }
  };

  useEffect(() => {
    if (isZoomed) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isZoomed]);

  if (url === '') {
    return <span>{fileName}</span>;
  }

  return (
    <div className="image-container">
      <Image
        onClick={handleThumbnailClick}
        alt="Thumbnail"
        boxSize={size + 'px'}
        src={url}
        objectFit="cover"
        // className="thumbnail"
      />

      {isFullscreen && (
        <div
          id="overlay"
          className={`overlay ${isZoomed ? 'zoomed' : ''}`}
          onClick={handleOverlayClick}
          style={{ overflow: isZoomed ? 'auto' : 'hidden' }}
        >
          <img
            id="fullscreen-image"
            src={url}
            alt="Fullscreen"
            className="fullscreen-image"
            ref={fullscreenImageRef}
            style={{
              transform: isZoomed ? `scale(${zoomFactor})` : 'scale(1)',
            }}
            // onDoubleClick={handleOverlayDoubleClick}
            onMouseDown={handleMouseDown}
          />
        </div>
      )}
    </div>
  );
};

export default Picture;
