// import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  collection,
  limit,
  orderBy,
  query,
  onSnapshot,
  doc,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { auth, db } from '../firebase';
import { Msg } from '../types';
import Messages from './Messages';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MESSAGE_COLLECTION, ROOM_COLLECTION } from '../App.constant';
import { remoteUser } from '../utils/userUtil';
import { useCall } from '../contexts/CallContext';

type Props = {
  isOnline: boolean;
};

const Talks = ({ isOnline }: Props) => {
  const [user] = useAuthState(auth);
  const [messages, setMessages] = useState<Msg[]>([]);
  const { setReceiving, setRoomId } = useCall();

  useEffect(() => {
    const uid = user?.uid;
    if (uid) {
      const uidRemote = remoteUser(uid);
      const unsubscribe = onSnapshot(
        doc(db, ROOM_COLLECTION, uidRemote),
        async (snapshot) => {
          const data = snapshot.data();
          var now = new Date();
          var thirtySecondsAgo = new Date(now.getTime() - 10 * 1000);
          if (data && data.callAt.seconds > thirtySecondsAgo.getTime() / 1000) {
            console.log('room available: ', data);
            setRoomId(data.roomId);
            setReceiving(true);
          }
        },
      );

      return () => {
        unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, MESSAGE_COLLECTION),
      orderBy('createdAt', 'desc'),
      limit(50),
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let fetchedMessages: Msg[] = [];
      QuerySnapshot.forEach((doc) => {
        // @ts-ignore
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        // @ts-ignore
        (a, b) => a.createdAt - b.createdAt,
      );
      setMessages(sortedMessages);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return user ? <Messages isOnline={isOnline} messages={messages} /> : null;
};

export default Talks;
