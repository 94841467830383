import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UploadProvider } from './contexts/UploadContext';
import AppRouter from './AppRouter';
import { CallProvider } from './contexts/CallContext';

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CallProvider>
          <UploadProvider>
            <AppRouter />
          </UploadProvider>
        </CallProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
