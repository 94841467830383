import React from "react";
import { Text } from "@chakra-ui/react";
import { formatRelative } from "date-fns";

const MessageTime = ({ seconds }: { seconds: number }) => {
  const time = formatRelative(new Date(seconds * 1000), new Date());
  return (
    <Text as="sup" color="grey">
      {time.replace("today ", "").replace("at ", "")}
    </Text>
  );
};

export default MessageTime;
