import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface CallContextType {
  calling: boolean;
  setCalling: Dispatch<SetStateAction<boolean>>;
  receiving: boolean;
  setReceiving: Dispatch<SetStateAction<boolean>>;
  roomId: string | null;
  setRoomId: Dispatch<SetStateAction<string | null>>;
  ongoingCall: boolean;
  setOngoingCall: Dispatch<SetStateAction<boolean>>;
  canCall: boolean;
  canEnd: boolean;
  ending: boolean;
  setEnding: Dispatch<SetStateAction<boolean>>;
  resetCall: () => void;
}

export const CallContext = React.createContext<CallContextType>(
  {} as CallContextType,
);

const CallProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [ongoingCall, setOngoingCall] = useState(false);
  const [calling, setCalling] = useState(false);
  const [receiving, setReceiving] = useState(false);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [ending, setEnding] = useState(false);

  const resetCall = () => {
    setOngoingCall(false);
    setCalling(false);
    setReceiving(false);
    setRoomId(null);
    setEnding(false);
  };

  return (
    <CallContext.Provider
      value={{
        calling,
        setCalling,
        receiving,
        setReceiving,
        roomId,
        setRoomId,
        ongoingCall,
        setOngoingCall,
        canCall: !ongoingCall && !calling && !receiving,
        canEnd: ongoingCall,
        ending,
        setEnding,
        resetCall,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};

const useCall = (): CallContextType => {
  const context = useContext(CallContext);

  if (context === undefined) {
    throw new Error('useCall must be used within an CallProvider');
  }
  return context;
};

export { CallProvider, useCall };
