import React, { useEffect } from 'react';
import { getIdTokenResult, signOut, User } from 'firebase/auth';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Hej from './components/Hej';
import { Die } from './components/Die';
import { auth, database } from './firebase';
import { ref, serverTimestamp, set } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUpload } from './contexts/UploadContext';
import { useCall } from './contexts/CallContext';

const AppRouter = () => {
  const { uploading } = useUpload();
  const { calling, receiving } = useCall();
  const [user] = useAuthState(auth);

  const router = createBrowserRouter([
    {
      path: '/hej',
      Component: Hej,
    },
    {
      id: 'all',
      path: '*',
      Component: Die,
    },
  ]);

  const gogo = () => {
    setTimeout(() => {
      window.location.href = 'https://www.google.se/';
    }, 500);
  };

  useEffect(() => {
    const logoutUser = () => {
      signOut(auth)
        .then(() => {
          gogo();
        })
        .catch(() => {
          gogo();
        });
    };

    const logout = () => {
      const uid = user?.uid;
      if (uid) {
        const isOfflineForDatabase = {
          online: false,
          lastChanged: serverTimestamp(),
        };
        const userStatusDatabaseRef = ref(database, '/status/' + uid);
        set(userStatusDatabaseRef, isOfflineForDatabase)
          .then(() => {
            logoutUser();
          })
          .catch(() => {
            logoutUser();
          });
      }
    };

    const getToken = async () => {
      const idToken = await getIdTokenResult(auth.currentUser as User);
      sessionStorage.setItem('token', idToken.token);
    };

    if (user) {
      getToken();
      if (
        !uploading &&
        !calling &&
        !receiving &&
        process.env.NODE_ENV === 'production'
      ) {
        console.log('setup logout: ', uploading);
        window.addEventListener('blur', logout);
      }
      return () => {
        // clearTimeout(time);
        window.removeEventListener('blur', logout);
      };
    }
  }, [user, uploading, calling, receiving]);

  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
};

export default AppRouter;
