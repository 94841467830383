// import { Box, Button } from '@chakra-ui/react';
// import { format } from 'date-fns';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ROOM_COLLECTION } from '../App.constant';
import { useCall } from '../contexts/CallContext';
import { auth, db } from '../firebase';
import useIsOnline from '../hooks/useIsOnline';

import './Call.css';
import Video from './Video';

const configuration = {
  iceServers: [
    {
      urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
    },
  ],
  iceCandidatePoolSize: 10,
};

// const ROOM_ID = format(new Date(), 'yyyy-MM-dd');

const Call = () => {
  const [user] = useAuthState(auth);
  const {
    calling,
    receiving,
    setRoomId,
    roomId,
    ongoingCall,
    setOngoingCall,
    ending,
    resetCall,
  } = useCall();
  const { isOnline } = useIsOnline();
  const [peerConnection, setPeerConnection] =
    useState<RTCPeerConnection | null>(null);
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);

  const hangUp = useCallback(async () => {
    console.log('hangUp');
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => track.stop());
    }

    if (peerConnection) {
      peerConnection.close();
    }

    resetCall();

    // Delete room on hangup
    // const db = firebase.firestore();
    // const roomRef = db.collection('rooms').doc(roomId);
    // const calleeCandidates = await roomRef.collection('calleeCandidates').get();
    // calleeCandidates.forEach(async (candidate) => {
    //   await candidate.ref.delete();
    // });
    // const callerCandidates = await roomRef.collection('callerCandidates').get();
    // callerCandidates.forEach(async (candidate) => {
    //   await candidate.ref.delete();
    // });
    // await roomRef.delete();
    //
    document.location.reload();
  }, [resetCall, localStream, remoteStream, peerConnection]);

  const openUserMedia = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user' },
      // video: { width: 1280, height: 720 },
    });
    setLocalStream(stream);
    setRemoteStream(new MediaStream());
  };

  const registerPeerConnectionListeners = useCallback(() => {
    if (peerConnection) {
      peerConnection.addEventListener('icegatheringstatechange', () => {
        console.log(
          `ICE gathering state changed: ${peerConnection.iceGatheringState}`,
        );
      });

      peerConnection.addEventListener('connectionstatechange', () => {
        // connectionState == disconnected hoile end call
        console.log(
          `Connection state change: ${peerConnection.connectionState}`,
        );
        if (
          peerConnection.connectionState === 'closed' ||
          peerConnection.connectionState === 'failed' ||
          peerConnection.connectionState === 'disconnected'
        ) {
          hangUp();
        }
      });

      peerConnection.addEventListener('signalingstatechange', () => {
        console.log(`Signaling state change: ${peerConnection.signalingState}`);
      });

      peerConnection.addEventListener('iceconnectionstatechange ', () => {
        console.log(
          `ICE connection state change: ${peerConnection.iceConnectionState}`,
        );
      });
    } else {
      console.warn('no peerConnection');
    }
  }, [peerConnection]);

  const joinCall = useCallback(async () => {
    console.log('Join call');

    if (
      localStream === null ||
      remoteStream === null ||
      peerConnection === null
    ) {
      console.log('Setup not complete');
      return;
    }
    registerPeerConnectionListeners();
    localStream.getTracks().forEach((track) => {
      console.log('receiving add local track');
      peerConnection.addTrack(track, localStream);
    });

    // Code for collecting ICE candidates below
    const roomRef = doc(db, `${ROOM_COLLECTION}/${roomId}`);
    const roomSnapshot = await getDoc(roomRef);
    if (!roomSnapshot.exists()) {
      console.warn('cannot find room');
      return;
    }
    peerConnection.addEventListener('icecandidate', async (event) => {
      if (!event.candidate) {
        console.log('Receiving: Got final candidate!');
        return;
      }
      console.log('Receiving: Got candidate: ', event.candidate);

      await addDoc(
        collection(db, `${ROOM_COLLECTION}/${roomId}/calleeCandidates`),
        event.candidate.toJSON(),
      );
    });
    // Code for collecting ICE candidates above

    peerConnection.addEventListener('track', (event) => {
      console.log('Got remote track:', event.streams[0]);
      event.streams[0].getTracks().forEach((track) => {
        console.log('Add a track to the remoteStream:', track);
        remoteStream.addTrack(track);
      });
    });

    console.log('send ANSWER');
    // Code for creating SDP answer below
    const roomData = roomSnapshot.data();
    if (!roomData || !roomData.offer) {
      console.log('no offer in room');
      return;
    }
    const offer = roomData.offer;
    console.log('Got offer:', offer);
    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await peerConnection.createAnswer();
    console.log('Created answer:', answer);
    await peerConnection.setLocalDescription(answer);

    const roomWithAnswer = {
      answer: {
        type: answer.type,
        sdp: answer.sdp,
      },
    };

    await setDoc(roomRef, roomWithAnswer, {
      merge: true,
    }).then(() => console.log('Room updated with answer'));
    // Code for creating SDP answer above

    // Listening for remote ICE candidates below
    onSnapshot(
      collection(db, `${ROOM_COLLECTION}/${roomId}`, 'callerCandidates'),
      (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          console.log(
            'doc changed: ',
            `${ROOM_COLLECTION}/${roomId}`,
            'CallerCandidates',
          );
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(
              `Got new remote ICE candidate: ${JSON.stringify(data)}`,
            );
            await peerConnection.addIceCandidate(new RTCIceCandidate(data));
          }
        });
      },
    );

    // Listening for remote ICE candidates above
  }, [
    roomId,
    localStream,
    remoteStream,
    peerConnection,
    registerPeerConnectionListeners,
  ]);

  const startCall = useCallback(async () => {
    if (
      localStream === null ||
      remoteStream === null ||
      peerConnection === null
    ) {
      console.log('Setup not complete');
      return;
    }

    console.log('Start Call');
    registerPeerConnectionListeners();
    // create room

    localStream.getTracks().forEach((track) => {
      console.log('added local track');
      peerConnection.addTrack(track, localStream);
    });

    // Code for collecting ICE candidates below
    peerConnection.addEventListener(
      'icecandidate',
      async (event: RTCPeerConnectionIceEvent) => {
        if (!event.candidate) {
          console.log('Got final candidate!', event);
          return;
        }
        await addDoc(
          collection(db, `${ROOM_COLLECTION}/${roomId}/callerCandidates`),
          event.candidate.toJSON(),
        );
      },
    );
    // Code for collecting ICE candidates above

    console.log('now create room');
    // Code for creating a room below
    const offer = await peerConnection.createOffer();

    await peerConnection.setLocalDescription(offer);

    console.log('Creating offer:', offer);
    const roomReference = await addDoc(collection(db, `${ROOM_COLLECTION}`), {
      offer: {
        type: offer.type,
        sdp: offer.sdp,
      },
    });
    setRoomId(roomReference.id);
    console.log('Created offer:', offer);

    // Code for creating a room above
    peerConnection.addEventListener('track', (event) => {
      console.log('Got remote track:', event.streams[0]);
      event.streams[0].getTracks().forEach((track) => {
        console.log('Add a track to the remoteStream:', track);
        remoteStream.addTrack(track);
      });
    });
    //
    // Listening for remote session description below
    onSnapshot(doc(db, ROOM_COLLECTION, roomReference.id), async (snapshot) => {
      const data = snapshot.data();
      if (!peerConnection.currentRemoteDescription && data && data.answer) {
        console.log('Got remote description: ', data.answer);
        const rtcSessionDescription = new RTCSessionDescription(data.answer);
        await peerConnection.setRemoteDescription(rtcSessionDescription);
      }
    });
    // // Listening for remote session description above
    //
    // // Listen for remote ICE candidates below
    onSnapshot(
      collection(
        db,
        `${ROOM_COLLECTION}/${roomReference.id}`,
        'calleeCandidates',
      ),
      (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          console.log(
            'doc changed: ',
            `${ROOM_COLLECTION}/${roomReference.id}`,
            'calleeCandidates',
          );
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(
              `Got new remote ICE candidate: ${JSON.stringify(data)}`,
            );
            await peerConnection.addIceCandidate(new RTCIceCandidate(data));
          }
        });
      },
    );
    // Listen for remote ICE candidates above

    await setDoc(doc(db, `${ROOM_COLLECTION}/${user?.uid}`), {
      callAt: serverTimestamp(),
      roomId: roomReference.id,
    });
  }, [
    roomId,
    localStream,
    remoteStream,
    peerConnection,
    user,
    setRoomId,
    registerPeerConnectionListeners,
  ]);

  // init call
  useEffect(() => {
    // console.log('startCall useEffect');
    // console.log('calling: ', calling);
    // console.log('receiving: ', receiving);
    if (!ongoingCall && peerConnection && localStream && remoteStream) {
      if (calling) {
        setOngoingCall(true);
        startCall();
      } else if (receiving) {
        setOngoingCall(true);
        joinCall();
      } else {
        console.log('WHAT THE HELL!');
      }
    }
  }, [
    setOngoingCall,
    joinCall,
    startCall,
    ongoingCall,
    calling,
    receiving,
    peerConnection,
    localStream,
    remoteStream,
  ]);

  // initiate call
  useEffect(() => {
    const setupCall = async () => {
      console.log('Call Setup');
      await openUserMedia();
      setPeerConnection(new RTCPeerConnection(configuration));
    };
    if ((calling || receiving) && !ongoingCall) {
      setupCall();
    }
  }, [calling, receiving, ongoingCall]);

  if (ending) {
    hangUp();
  }

  if (!isOnline || (!calling && !receiving)) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#282c34',

        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: '100%',
        height: 'calc(100% - 300px)',
        // background-color: rgba(0, 0, 0, 0.8), /* Slight transparency for overlay effect */
        zIndex: 99,
        display: 'flex',
        justifyContent: 'enter',
        alignItems: 'center',
        border: '1px solid #fff',
      }}
    >
      <Video
        style={{
          position: 'absolute',
          width: '20%',
          height: '20%',
          bottom: '10px',
          right: '10px',
          border: '1px solid #fff',
          objectFit: 'cover',
        }}
        srcObject={localStream}
        muted
        autoPlay
        playsInline
      />
      <Video
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        srcObject={remoteStream}
        autoPlay
        playsInline
      />
    </div>
  );
};

export default Call;
